import { AccountResponse } from '@/types/User'
import axios from 'axios'
import { ApiResponse, apiUrl, handleApiResponse } from '../util'

export async function account(): Promise<AccountResponse> {
	return handleApiResponse(axios(apiUrl('/api/account'))).then(
		(response: ApiResponse) => {
			if (response.success) {
				return {
					success: true,
					account: response.response.data.data,
				} as AccountResponse
			}

			return {
				success: false,
				error: response.error!.message,
			}
		},
	)
}
