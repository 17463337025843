import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

export function useBugsnag() {
	return Bugsnag
}

export function makeBugsnag(apiKey: string, releaseStage: string) {
	if (apiKey.length == 0) {
		return { install: () => {} }
	}

	Bugsnag.start({
		apiKey,
		plugins: [new BugsnagPluginVue()],
		releaseStage,
		enabledReleaseStages: ['production'],
	})

	return Bugsnag.getPlugin('vue')
}
