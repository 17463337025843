import currencyFormat from '@/util/currencyFormat'
import '@/util/strings'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { createApp } from 'vue'
import './style.css'

import router from '@/router'
import { RouterLink } from 'vue-router'

import { createPinia } from 'pinia'

import App from '@/App.vue'
import Main from '@/components/layouts/Main.vue'

import { makeBugsnag, useBugsnag } from '@/modules/bugsnag'
import { createLogger, withBugsnag } from '@/modules/log'

import axios from 'axios'
import timeago from 'vue-timeago3'
import './icons'

axios.defaults.withCredentials = true

// initialize pinia
const pinia = createPinia()
// pinia.use(piniaPluginPersistedstate)

const app = createApp(App)
if (import.meta.env.VITE_BUGSNAG_API_KEY) {
	app.use(
		makeBugsnag(
			import.meta.env.VITE_BUGSNAG_API_KEY,
			import.meta.env.VITE_ENV,
		),
	)
}
withBugsnag(useBugsnag())
app.use(createLogger({ env: import.meta.env.VITE_ENV }))
app.use(router)
app.use(pinia) // add pinia to app
app.use({
	install: (app) => {
		app.config.globalProperties.$format = currencyFormat
		app.config.globalProperties.$token = 'Miles'
	},
})

app.use(timeago)
app.component('fa', FontAwesomeIcon)
app.component('router-link', RouterLink)
app.component('layout-main', Main)
app.mount('#app')
