<template>
	<div
		class="bg-teal-800/10 p-4 rounded-lg relative hover:bg-teal-700/10 hover:cursor-pointer"
		@click="expanded = !expanded"
	>
		<!-- <button class="absolute bottom-2 right-4"> -->
		<!-- 	<fa v-if="!expanded" icon="fa-solid fa-caret-down" class="text-gray-500" /> -->
		<!-- 	<fa v-else icon="fa-solid fa-caret-up" class="text-gray-500" /> -->
		<!-- </button> -->
		<!---->
		<!-- <button v-if="isAbleToDelete" -->
		<!-- 	class="text-teal-800/40 hover:text-red-600 rounded-full absolute top-4 right-4 flex justify-center items-center" -->
		<!-- 	@click.stop="deleteEntry"> -->
		<!-- 	<fa icon="fa-solid fa-trash" /> -->
		<!-- </button> -->

		<div class="flex space-x-4 justify-between items-center">
			<div
				class="font-extralight text-gray-800 tracking-tighter text-sm text-center"
				:class="[isWinner ? 'text-gray-800' : 'text-gray-800/10']"
			>
				<fa
					icon="fa-solid fa-trophy"
					class="text-2xl"
					:class="[isWinner ? 'text-teal-800' : '']"
				/>
				<!-- <p class="font-bold"> -->
				<!-- 	<span>{{ props.entries }}</span> -->
				<!-- 	<span>x</span> -->
				<!-- </p> -->
			</div>
			<div
				class="text-left flex-grow text-teal-800 text-sm leading-tight flex justify-between leading-tight"
			>
				<p class="font-medium">
					{{ props.entries }}
					{{ props.entries != 1 ? 'entries' : 'entry' }}
				</p>
				<p class="text-gray-600 tracking-tight">
					<span class="text-teal-800 font-bold">{{
						winningPicks.length
					}}</span>
					of {{ props.picks.length }}
				</p>
			</div>
			<div class="flex items-top justify-end order-2">
				<div class="space-x-4 text-sm">
					<!--
						class="-bg-teal-800/15 text-teal-800/85 md:px-4 md:py-2 rounded-xl -shadow hover:bg-teal-800 hover:text-white">
						-->

					<button
						:class="[
							expanded ? 'text-teal-800' : 'text-teal-800/40',
						]"
					>
						<fa icon="fa-solid fa-list" />
					</button>
					<button
						class="text-teal-800/40 hover:text-red-600"
						@click.stop="isDeleteModalOpen = true"
					>
						<fa icon="fa-solid fa-trash" />
					</button>
				</div>
			</div>
		</div>
		<div
			v-if="expanded"
			class="flex justify-between items-center text-sm text-teal-800 mt-2"
		>
			<p class="space-x-1">
				<span class="font-medium">Outcome:</span>
				<span class="text-gray-600">
					<span v-if="!isFinalized">Pending</span>
					<span v-else>Final</span>
				</span>
			</p>
			<p class="space-x-1 text-right">
				<span class="font-medium">Prize:</span>
				<span class="text-gray-600"
					>{{ $format(props.entries * 2000) }} {{ $token }}*</span
				>
			</p>
		</div>
		<div class="">
			<div class="flex-grow text-gray-500">
				<div
					class="flex justify-between text-lg font-semibold text-teal-800 text-left"
				>
					<p v-if="props.firstPickBonus" class="flex items-center">
						<span class="group relative flex">
							<fa
								icon="fa-solid fa-star"
								class="text-yellow-500 text-xs mr-1"
							/>
							<span
								class="hidden group-hover:block text-sm font-normal absolute w-64 bg-white rounded shadow px-4 py-2 z-10"
							>
								<span class="font-bold">First pick bonus.</span>
								Your first pick, each week, earns you a mileage
								bonus regardless of the outcome of your pick.
							</span>
						</span>
						{{ $format(props.firstPickBonus) }} {{ $token }}
					</p>
					<p v-if="props.miles > 0">
						{{ $format(props.miles) }} {{ $token }}
					</p>
				</div>
				<p class="text-sm tracking-tight mt-2" v-if="!expanded">
					<span
						v-for="(pick, idx) in props.picks"
						:key="`pick-${pick.id}`"
					>
						{{ pick.hero_name
						}}<span v-if="idx < props.picks.length - 1">, </span>
					</span>
				</p>
				<div v-if="expanded" class="space-y-3 mt-3">
					<div
						v-for="(pick, idx) in props.picks"
						:key="`pick-${idx}`"
						class="flex"
					>
						<p>
							<fa
								icon="fa-solid fa-check-circle"
								class="mr-4 mt-1 text-2xl"
								:class="[
									pick.actual_points &&
									pick.actual_points > pick.projected_points
										? 'text-teal-800'
										: 'text-gray-300',
								]"
							/>
						</p>
						<div>
							<p class="text-gray-700">{{ pick.hero_name }}</p>
							<p class="text-sm">
								<span
									:class="[
										{
											'font-semibold font-medium':
												props.isFinalized &&
												pick.actual_points,
											'text-teal-800':
												pick.actual_points &&
												pick.actual_points >
													pick.projected_points,
											'text-red-500': props.isFinalized,
										},
									]"
								>
									{{ $format(pick.actual_points || 0) }}
								</span>
								<span class="text-gray-500">
									/
									{{
										parseInt(pick.projected_points) ==
										pick.projected_points
											? $format(pick.projected_points)
											: 0
									}}</span
								>
								Points
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<delete-entry-modal
		v-if="isDeleteModalOpen"
		@close="isDeleteModalOpen = false"
		@reload="reload"
		:picks="props.picks"
		:entries="props.entries"
		:id="props.id"
		:deleteHandler="deleteEntry"
	>
	</delete-entry-modal>

	<div
		class="bg-gray-100 flex justify-center items-center rounded-lg shadow-md py-2"
		@click="openModal"
	>
		<div class="text-center">Hero Stats</div>
	</div>

	<div>
		<PlayerModal
			v-if="isModalOpen"
			:hero="currentHero"
			:heroList="filteredHeroList"
			:createModal="false"
			:currentIndex="currentIndex"
			@close="closeModal"
			@change="changePlayer"
		/>
	</div>
</template>
<script lang="ts" setup>
import { HeroPick } from '@/types/Hero'
import { ComputedRef, computed, ref } from 'vue'
import DeleteEntryModal from '@/components/Modal/DeleteEntryModal.vue'
import PlayerModal from '@/components/Heroes/PlayerStatsModal.vue'
import { usePickSixStore } from '@/stores/PickSixStore'

const pickSixStore = usePickSixStore()
const isDeleteModalOpen = ref<boolean>(false)

const emit = defineEmits<{
	(e: 'delete'): void
	(e: 'reload'): void
}>()

const props = defineProps<{
	entries: number
	firstPickBonus: number
	miles: number
	picks: HeroPick[]
	heroList: HeroPick[]
	expanded?: boolean
	isFinalized?: boolean
	id: string
}>()

const expanded = ref(props.expanded || false)

const isModalOpen = ref(false)
const currentIndex = ref(0)

function findHeroInList(playerName: string) {
	return (
		filteredHeroList.value.find(
			(hero) => hero.player_name === playerName,
		) || null
	)
}

const filteredHeroList = computed(() => {
	return props.heroList.filter((hero) =>
		props.picks.some((pick) => pick.hero_name === hero.hero_name),
	)
})

function openModal() {
	const firstHero = props.picks[0]
	if (firstHero) {
		const hero = findHeroInList(firstHero.player_name)
		if (hero) {
			currentIndex.value = filteredHeroList.value.indexOf(hero)
		}
	}
	isModalOpen.value = true
	document.body.style.overflow = 'hidden' // Disable scrolling
}

function closeModal() {
	isModalOpen.value = false
	document.body.style.overflow = '' // Enable scrolling
}

function changePlayer(index: number) {
	const hero = filteredHeroList.value[index]
	if (hero) {
		currentIndex.value = index
	}
}

const currentHero = computed(() => {
	return filteredHeroList.value[currentIndex.value] || null
})

const isWinner: ComputedRef<boolean> = computed(() => winningEntry(props.picks))

function winningEntry(picks: HeroPick[]): boolean {
	return picks.reduce(
		(winner: boolean, pick: HeroPick) =>
			winner &&
			pick.actual_points !== null &&
			pick.actual_points > pick.projected_points,
		true,
	)
}

const winningPicks = computed((): HeroPick[] => {
	return props.picks.filter((p: HeroPick) => {
		return p.actual_points !== null && p.actual_points > p.projected_points
	})
})

const isAbleToDelete = computed(() => {
	return props.picks.reduce(
		(isAble: boolean, pick: HeroPick) =>
			isAble && pick.actual_points === null,
		true,
	)
})

async function deleteEntry() {
	return pickSixStore.deleteEntry(props.id)
}

function ifOver(pick: HeroPick) {
	return pick.actual_points && pick.actual_points > pick.projected_points
}

function reload() {
	isDeleteModalOpen.value = false
	emit('reload')
}
</script>
