<template>
	<div class="min-h-[100svh] flex flex-col">
		<slot name="header" v-if="showHeader">
			<header
				class="bg-gradient-to-br from-black to-teal-800 text-white sticky top-0 z-30"
			>
				<div
					class="flex w-full max-w-screen-md justify-between mx-auto px-4 py-2 h-16 items-center"
				>
					<div class="flex gap-4 items-center">
						<HthHeader class="w-12" />
						<RouterLink
							:to="{ name: 'home' }"
							class="group flex items-center gap-1"
						>
							<fa icon="fa-solid fa-home" />
							<span
								class="font-medium tracking-tight group-hover:underline"
								>Home</span
							>
						</RouterLink>

						<RouterLink
							:to="{ name: 'leaderboard' }"
							class="group items-center gap-1 hidden md:flex"
						>
							<fa icon="fa-solid fa-list" />
							<span
								class="font-mediumtracking-tight group-hover:underline"
								>Leaderboard</span
							>
						</RouterLink>
					</div>

					<AuthBlock @logout="logout" class="justify-end" />
				</div>
			</header>
		</slot>

		<section class="pb-10 flex-grow w-full max-w-screen-md mx-auto md:pb-0">
			<!-- main content area -->
			<slot name="default" />
		</section>

		<div class="max-w-screen-md w-full mx-auto mt-8">
			<div class="grid grid-cols-2 p-4 items-start text-xs md:text-sm">
				<div>
					<p>
						Powered by
						<a
							href="https://blokpax.com"
							target="_blank"
							rel="nofollow noreferrer"
							class="hover:underline hover:text-teal-800 underline-offset-2"
							>Blokpax</a
						>
					</p>
				</div>
				<div class="text-right">
					<a
						href="https://blokpax.com/terms-of-service"
						target="_blank"
						rel="nofollow noreferrer"
						class="block hover:underline hover:text-teal-800 underline-offset-2"
						>Terms of Service</a
					>
					<a
						href="https://blokpax.com/privacy"
						target="_blank"
						rel="nofollow noreferrer"
						class="block hover:underline hover:text-teal-800 underline-offset-2"
						>Privacy Policy</a
					>
				</div>
			</div>
		</div>

		<footer class="w-full mx-auto md:hidden sticky bottom-0 bg-gray-100">
			<!-- footer buttons area -->
			<div
				class="grid grid-cols-3 text-center text-gray-500 items-stretch w-full max-w-72 mx-auto"
			>
				<div class="p-4 bg-gray-100">
					<router-link
						custom
						:to="{ name: 'home' }"
						v-slot="{ navigate }"
					>
						<button @click="navigate" class="hover:text-teal-800">
							<fa icon="fa-solid fa-calendar" />
						</button>
					</router-link>
				</div>

				<div class="bg-gray-100 flex justify-center items-center">
					<!-- <button class="absolute bg-teal-800 text-white w-full aspect-square rounded-full left-0 -top-7 text-3xl shadow-[3px_-3px_2px_1px_rgba(165,165,165,.8)]">
						<fa icon="fa-solid fa-plus" />
					</button> -->
					<div class="w-full px-4 flex-shrink-0 relative -top-6">
						<slot name="center-button">
							<router-link
								custom
								:to="{ name: 'create' }"
								v-slot="{ navigate }"
							>
								<button
									@click="navigate"
									class="bg-teal-800 text-white w-full aspect-square rounded-full"
								>
									<fa icon="fa-solid fa-plus" />
								</button>
							</router-link>
						</slot>
					</div>
				</div>
				<div class="p-4 bg-gray-100">
					<RouterLink
						:to="{ name: 'leaderboard' }"
						v-slot="{ navigate }"
					>
						<button @click="navigate" class="hover:text-teal-800">
							<fa icon="fa-solid fa-list" />
						</button>
					</RouterLink>
				</div>
			</div>
		</footer>
	</div>
</template>
<script lang="ts" setup>
import HthHeader from '@/components/HthHeader.vue'
import AuthBlock from '@/components/AuthBlock.vue'

withDefaults(
	defineProps<{
		showHeader?: boolean
	}>(),
	{
		showHeader: true,
	},
)

function logout() {
	window.top!.location = '/logout'
}
</script>
