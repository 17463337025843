<template>
	<modal :close-on-click-away="true" @close="close">
		<div class="bg-white rounded-xl">
			<div class="p-6 relative">
				<h1 class="text-2xl font-bold" ref="title">
					{{
						isDeleted
							? 'Success'
							: 'Are you sure you want to delete this ticket?'
					}}
				</h1>
				<template v-if="isDeleted">
					Ticket successfully deleted.
					<span class="text-teal-800 font-bold"
						>{{ props.entries }}
						{{ $props.entries > 1 ? 'Entries' : 'Entry' }}</span
					>
					has been returned for you to use this week.
				</template>
				<template v-else>
					<div
						class="grid grid-cols-3 gap-4 text-center mt-4"
						:class="[isDeleting ? 'opacity-60' : '']"
					>
						<div
							v-for="(hero, idx) in props.picks"
							:key="`hero-${hero?.id}`"
							class="relative items-start space-y-2"
						>
							<p
								class="bg-teal-800 text-white absolute w-8 h-8 rounded-full flex items-center justify-center"
							>
								#{{ idx + 1 }}
							</p>
							<div
								class="aspect-square rounded-lg overflow-hidden"
							>
								<img :src="hero?.image_url" />
							</div>
							<div class="">
								<p
									:title="hero?.hero_name"
									class="overflow-hidden text-base md:text-lg font-medium leading-5 whitespace-nowrap text-ellipsis"
								>
									{{ hero?.hero_name }}
								</p>
								<p class="text-xs md:text-base">
									Projected:
									<span>{{ hero?.projected_points }}</span>
									pts
								</p>
							</div>
						</div>
					</div>
					<div
						class="flex items-center justify-end mt-4 p-2 md:py-4"
						:class="[isDeleting ? 'opacity-60' : '']"
					>
						<p>
							<span class="text-teal-800 font-bold"
								>{{ props.entries }}
								{{
									$props.entries > 1 ? 'Entries' : 'Entry'
								}}</span
							>
							will be returned to you for this week.
						</p>
					</div>
					<div
						class="text-red-800 text-sm bg-red-100 rounded-xl py-2 px-4 my-2"
						v-if="hasErrored"
					>
						{{ errorMessage }}
					</div>
				</template>
			</div>
			<div
				class="flex justify-end py-4 px-6 bg-gray-200 rounded-b-xl space-x-4"
			>
				<button
					class="text-gray-500 font-medium enabled:hover:text-black disabled:text-gray-400 disabled:cursor-not-allowed"
					@click.prevent="close"
					:disabled="isDeleting"
				>
					{{ isDeleted ? 'Close' : 'Cancel' }}
				</button>
				<button
					class="bg-red-600 text-white shadow px-4 py-2 rounded-lg enabled:hover:bg-red-700 disabled:bg-gray-400 disabled:cursor-not-allowed"
					@click.prevent="doDelete"
					:disabled="isDeleting"
					v-if="!isDeleted"
				>
					<span v-if="isDeleting"
						>Deleting <fa icon="fa fa-spinner" spin class="ml-1"
					/></span>
					<span v-else>
						<span v-if="isConfirmed">Confirm Delete</span>
						<span v-else>Delete</span>
					</span>
				</button>
			</div>
		</div>
	</modal>
</template>
<script lang="ts" setup>
import Modal from '@/components/Modal/Modal.vue'
import { useLogger } from '@/modules/log'
import { DeleteEntryResponse } from '@/types/Entry'
import { HeroPick } from '@/types/Hero'
import { ref } from 'vue'

const props = defineProps<{
	picks: HeroPick[]
	entries: number
	id: string
	deleteHandler: (id: string) => Promise<DeleteEntryResponse>
}>()

const emit = defineEmits<{
	(e: 'close'): void
	(e: 'reload'): void
}>()

const isConfirmed = ref<boolean>(false)
const isDeleting = ref<boolean>(false)
const isDeleted = ref<boolean>(false)
const hasErrored = ref<boolean>(false)
const errorMessage = ref<string>('')

const log = useLogger()

function close() {
	if (isDeleting.value) return

	if (isDeleted.value) {
		emit('reload')
	} else {
		emit('close')
	}
}

async function doDelete() {
	if (!isConfirmed.value) {
		isConfirmed.value = true
		return
	}

	isDeleting.value = true

	props
		.deleteHandler(props.id)
		.then((result) => {
			if (result.success) {
				isDeleted.value = true
			} else {
				hasErrored.value = true
				errorMessage.value = result.message as string
			}
		})
		.catch((err) => {
			log.error(err.message, { err })
			errorMessage.value =
				err.response?.data.message ||
				'There was a problem saving your entry. Try again.'
			hasErrored.value = true
		})
		.finally(() => {
			isDeleting.value = false
		})
}
</script>
