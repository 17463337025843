import { ListEntryResponse } from '@/types/Entry'
import axios from 'axios'
import { ApiResponse, apiUrl, handleApiResponse } from '../util'

export async function entries(
	season: string,
	week: string | null,
): Promise<ListEntryResponse> {
	return handleApiResponse(
		axios({
			method: 'GET',
			url: apiUrl(
				`/api/account/season/${season}/weeks/${week || 'current'}`,
			),
		}),
	).then((apiResponse: ApiResponse): ListEntryResponse => {
		if (apiResponse.success) {
			return {
				success: true,
				entries: apiResponse.response.data.data.entries,
			}
		}

		return {
			success: false,
			message: apiResponse.error!.message,
		}
	})
}
