<template>
	<div class="flex flex-col mr-4">
		<div
			ref="entryHeroRow"
			:key="`hero-${hero.id}`"
			class="flex justify-start p-2 space-x-4 cursor-pointer hover:bg-gray-50"
			:class="[
				highlighted ? 'bg-gray-50' : 'bg-white',
				{ 'opacity-50': disabled },
			]"
			@click="handlePick"
		>
			<div class="w-1/6 shrink-0">
				<img
					:src="encodeURI(hero?.image_url)"
					class="rounded-lg border-2"
					:class="[
						highlighted ? 'border-teal-600' : 'border-transparent',
						selected ? 'opacity-60' : 'opacity-100',
					]"
				/>
			</div>
			<div class="flex-grow relative">
				<p
					class="text-xl font-semibold"
					:class="[selected ? 'opacity-60' : 'opacity-100']"
				>
					{{ hero.hero_name }}
				</p>
				<div class="">
					<p class="text-sm text-gray-500">
						<span class="font-medium">Favorite player: </span>
						<span>{{ hero.player_name }}</span>
					</p>
					<p
						class="tracking-tight leading-none"
						:class="[selected ? 'opacity-60' : 'opacity-100']"
					>
						<span class="text-sm text-gray-500 font-medium"
							>Projected Points:</span
						>
						<span class="text-gray-500 text-sm">
							{{ hero.projected_points }} pts
						</span>
					</p>
					<p
						class="tracking-tight leading-none"
						:class="[selected ? 'opacity-60' : 'opacity-100']"
					>
						<span class="text-sm text-gray-500 font-medium"
							>Expected Games:</span
						>
						<span class="text-gray-500 text-sm">
							{{ hero.expected_games ?? 'N/A' }}
						</span>
					</p>
					<p v-if="disabled">
						<span class="text-xs text-red-500">Ineligible</span>
					</p>
				</div>
			</div>
			<div class="self-center">
				<button
					v-if="!selected"
					class="inline-block px-4 py-2 bg-teal-800 rounded-full text-white aspect-square shadow-md disabled:bg-gray-100 disabled:text-gray-200"
					:disabled="disabled"
				>
					<fa icon="fa-solid fa-plus" />
				</button>
				<button
					v-else
					class="inline-block px-4 py-2 bg-red-300 text-white rounded-full aspect-square shadow-md"
				>
					<fa icon="fa-solid fa-minus" />
				</button>
			</div>
		</div>

		<div
			class="bg-gray-100 flex justify-center items-center rounded-lg shadow-md py-2"
			@click="openModal"
		>
			<div class="text-center">Hero Stats</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { Hero, HeroPick } from '@/types/Hero'
import { ref, watch, onUnmounted } from 'vue'
const entryHeroRow = ref<HTMLElement | null>(null)
const props = withDefaults(
	defineProps<{
		hero: HeroPick
		heroList: Hero[]

		disabled: boolean
		highlighted: boolean
		selected: boolean
	}>(),
	{
		disabled: false,
		highlighted: false,
		selected: false,
	},
)

const emit = defineEmits<{
	add: [hero: Hero]
	remove: [hero: Hero]
	updateModalStatus: [index: number, boolean]
}>()

function addHero() {
	emit('add', props.hero)
}

function removeHero() {
	emit('remove', props.hero)
}

function handlePick() {
	if (props.disabled) return
	if (props.selected) {
		removeHero()
	} else {
		addHero()
	}
}

watch(
	() => props.highlighted,
	(highlighted) => {
		if (highlighted) {
			entryHeroRow.value?.scrollIntoView({
				behavior: 'instant',
				block: 'nearest',
			})
		}
	},
)

const currentIndex = ref(0)

function openModal() {
	currentIndex.value = props.heroList.findIndex(
		(p) => p.player_name === props.hero.player_name,
	)

	emit('updateModalStatus', currentIndex.value, true)

	document.body.style.overflow = 'hidden' // Disable scrolling
}

// Cleanup on component unmount
onUnmounted(() => {
	document.body.style.overflow = '' // Ensure scrolling is enabled
})
</script>
