<template>
	<Mock>
		<div>
			<div
				class="flex space-x-2 sticky top-0 bg-white shadow overflow-x-auto z-10"
			>
				<div
					v-for="(wk, idx) in weeks"
					:key="`week-${idx}`"
					class="whitespace-nowrap"
					:class="['text-gray-400']"
				>
					<template v-if="idx > 0">
						<router-link
							custom
							:to="{
								name: 'week-history',
								params: { week: idx },
							}"
							v-slot="{ navigate }"
						>
							<button
								@click="navigate"
								class="px-4 py-2"
								type="button"
							>
								Week {{ weeks - idx }}
							</button>
						</router-link>
					</template>
					<template v-else>
						<router-link
							custom
							:to="{ name: 'home' }"
							v-slot="{ navigate }"
						>
							<button
								@click="navigate"
								class="px-4 py-2"
								type="button"
							>
								Week {{ weeks - idx }}
							</button>
						</router-link>
					</template>
				</div>
			</div>
		</div>
	</Mock>
</template>
<script lang="ts" setup>
import Mock from '@/components/Mock.vue'
import ParlayResult from '@/components/Mockups/Partials/ParylayResult.vue'
const weeks = 6
</script>
