<template>
	<div
		class="bg-teal-800/10 p-4 rounded-lg relative"
		@click="expanded = !expanded"
	>
		<button class="absolute bottom-2 right-4">
			<fa
				v-if="!expanded"
				icon="fa-solid fa-caret-down"
				class="text-gray-500"
			/>
			<fa v-else icon="fa-solid fa-caret-up" class="text-gray-500" />
		</button>

		<div class="flex space-x-4">
			<div
				class="font-extralight text-gray-800 tracking-tighter text-sm text-center"
			>
				<fa
					icon="fa-solid fa-trophy"
					class="text-4xl text-teal-800 inline"
				/>
				<p>
					<span class="">3</span>
					<span class="">x</span>
				</p>
			</div>

			<div class="flex-grow text-gray-500">
				<div
					class="flex justify-between text-lg font-semibold text-teal-800 text-left"
				>
					<p v-if="props.firstPickBonus" class="flex items-center">
						<span class="group relative flex">
							<fa
								icon="fa-solid fa-star"
								class="text-yellow-500 text-xs mr-1"
							/>
							<span
								class="hidden group-hover:block text-sm font-normal absolute w-64 bg-white rounded shadow px-4 py-2 z-10"
							>
								<span class="font-bold">First pick bonus.</span>
								Your first pick, each week, earns you a mileage
								bonus regardless of the outcome of your pick.
							</span>
						</span>
						{{ $format(props.firstPickBonus) }} Miles
					</p>
					<p>{{ $format(props.miles) }} Miles</p>
				</div>
				<p class="text-sm" v-if="!expanded">
					{{ props.picks.map((p) => p.name).join(', ') }}
				</p>
				<div v-if="expanded" class="mt-2 space-y-3">
					<div
						v-for="(pick, idx) in props.picks"
						:key="`pick-${idx}`"
						class="flex"
					>
						<p>
							<fa
								icon="fa-solid fa-check-circle"
								class="text-teal-800 mr-2 mt-1 text-xl"
							/>
						</p>
						<div>
							<p class="text-gray-700">{{ pick.name }}</p>
							<p class="text-sm">
								<span class="text-teal-800 font-medium">
									{{ $format(pick.points) }}
								</span>
								<span class="text-gray-500">
									/ {{ $format(pick.projection) }}</span
								>
								Points
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script lang="ts" setup>
import { ref } from 'vue'

type heroPick = {
	name: string
	projection: number
	points: number
}

const props = defineProps<{
	entries: number
	firstPickBonus: number
	miles: number
	picks: heroPick[]
}>()

const expanded = ref(false)
</script>
