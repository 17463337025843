<template>
	<modal>
		<div class="bg-white rounded-xl">
			<div class="p-6 relative">
				<div
					v-if="saved"
					class="z-10 absolute p-6 text-green-800 bg-green-100 rounded-xl left-3 right-3 top-1/3 text-center flex flex-col justify-center items-center shadow"
				>
					<h2 class="font-bold text-tight text-2xl">
						<fa icon="check-circle" class="mr-1" /> Success!
					</h2>
					<p class="text-xl my-4">
						Your <span v-if="entries > 1">entries</span
						><span v-else>entry</span> has been created.
					</p>

					<button
						class="bg-teal-800 text-white shadow px-4 py-2 rounded-lg enabled:hover:bg-teal-900"
						@click="navigate('entries')"
					>
						View your entries
					</button>
				</div>
				<h1 class="text-2xl font-bold" ref="title">
					Confirm your pick lineup
				</h1>
				<div
					class="grid grid-cols-3 gap-4 text-center mt-4"
					:class="[saved ? 'opacity-60' : '']"
				>
					<div
						v-for="(hero, idx) in heroes"
						:key="`hero-${hero?.id}`"
						class="relative items-start space-y-2"
					>
						<p
							class="bg-teal-800 text-white absolute w-8 h-8 rounded-full flex items-center justify-center"
						>
							#{{ idx + 1 }}
						</p>
						<div class="aspect-square rounded-lg overflow-hidden">
							<img :src="hero?.image_url" />
						</div>
						<div class="">
							<p
								:title="hero?.hero_name"
								class="overflow-hidden text-lg font-medium leading-5 whitespace-nowrap text-ellipsis"
							>
								{{ hero?.hero_name }}
							</p>
							<p>
								Projected:
								<span>{{ hero?.projected_points }}</span> pts
							</p>
						</div>
					</div>
				</div>

				<div
					class="flex items-center border rounded-lg mt-6"
					:class="[saving || saved ? 'bg-gray-100' : '']"
				>
					<label
						class="font-medium px-3 py-2 bg-gray-200 text-gray-500 rounded-l-lg"
						for="quantity"
					>
						Qty
					</label>
					<p class="flex-shrink flex-grow mx-2">
						<input
							type="tel"
							id="quantity"
							class="w-full bg-transparent outline-none ring-0 read-only:text-gray-400"
							placeholder="1"
							v-model="entries"
							:readonly="saving || saved"
						/>
					</p>
					<p class="mx-2">
						<button
							class="text-sm text-teal-800 px-2 py-.5 border border-teal-800 rounded enabled:hover:text-white enabled:hover:bg-teal-800 enabled:hover:shadow disabled:border-gray-300 disabled:text-gray-400"
							@click="entries = entryLimit"
							:disabled="saving || saved"
						>
							Max
						</button>
					</p>
					<p
						class="bg-gray-200 text-gray-500 py-2.5 px-3 whitespace-nowrap text-sm italic rounded-r-lg"
					>
						<span>of </span>
						<span>{{ entryLimit }}</span>
					</p>
				</div>
				<div
					class="text-red-800 text-sm bg-red-100 rounded-xl py-2 px-4 my-2"
					v-if="errored"
				>
					{{ errorMessage }}
				</div>
			</div>
			<div
				class="flex justify-end py-4 px-6 bg-gray-200 rounded-b-xl space-x-4"
				v-if="saved == false"
			>
				<button
					class="text-gray-500 font-medium enabled:hover:text-teal-800 disabled:text-gray-400 disabled:cursor-not-allowed"
					@click.prevent="$emit('close')"
					:disabled="saving"
				>
					Edit
				</button>
				<button
					class="bg-teal-800 text-white shadow px-4 py-2 rounded-lg enabled:hover:bg-teal-900 disabled:bg-gray-400 disabled:cursor-not-allowed"
					@click.prevent="doSave()"
					:disabled="saving || !isValid"
				>
					<span v-if="!saving">Save</span>
					<span v-else
						>Saving <fa icon="fa fa-spinner" spin class="ml-1"
					/></span>
				</button>
			</div>
			<div
				class="flex justify-end py-4 px-6 bg-gray-200 rounded-b-xl space-x-4"
				v-if="saved == true"
			>
				<button
					class="text-gray-500 font-medium enabled:hover:text-teal-800"
					@click.prevent="$emit('reset')"
				>
					Create Another
				</button>
				<button
					class="bg-teal-800 text-white shadow px-4 py-2 rounded-lg enabled:hover:bg-teal-900"
					@click="navigate('entries')"
				>
					View Entries
				</button>
			</div>
		</div>
	</modal>
</template>
<script lang="ts" setup>
import { ValidationErrors } from '@/api/util'
import Modal from '@/components/Modal/Modal.vue'
import { useLogger } from '@/modules/log'
import { SaveEntryResponse } from '@/types/Entry'
import { Hero } from '@/types/Hero'
import { ComputedRef, computed, onUnmounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

const title = ref<HTMLElement | null>(null)

const router = useRouter()

type SaveHandler = (entries: number) => Promise<SaveEntryResponse>

const props = defineProps<{
	heroes: Hero[] | null[]
	entryLimit: number
	saveHandler: SaveHandler
}>()

const $emit = defineEmits<{
	close: []
	reset: []
	save: [entries: number]
}>()

const entries = ref<number>(1)
const saving = ref<boolean>(false)
const saved = ref<boolean>(false)
const errored = ref<boolean>(false)
const errorMessage = ref<string>('')
const errors = ref<ValidationErrors>({})

const log = useLogger()

function resetErrors() {
	errored.value = false
	errorMessage.value = ''
	errors.value = {}
}

const isValid: ComputedRef<boolean> = computed(() => {
	return (
		!errored.value &&
		!saving.value &&
		!saved.value &&
		entries.value > 0 &&
		entries.value <= props.entryLimit
	)
})

const stopWatching = watch(entries, (newEntries: number | string) => {
	if (newEntries === '') {
		resetErrors()
		return
	}
	const qty = Number(newEntries)
	if (isNaN(qty) || qty < 1) {
		errored.value = true
		errorMessage.value = 'You must enter a valid quantity.'
	} else if (qty > props.entryLimit) {
		errored.value = true
		errorMessage.value = `You can only save up to ${props.entryLimit} entries.`
	} else {
		resetErrors()
	}
})

onUnmounted(() => {
	stopWatching()
})

function doSave() {
	saving.value = true
	resetErrors()
	// debugger;

	props
		.saveHandler(entries.value)
		.then((response: SaveEntryResponse) => {
			if (response.success) {
				saved.value = true
			} else {
				errored.value = true
				errorMessage.value =
					response.message ||
					'There was a problem saving your entry. Try again.'
				errors.value = response.errors || {}
			}

			saving.value = false
			title.value?.scrollIntoView({
				behavior: 'smooth',
			})
		})
		.catch((err) => {
			errored.value = true
			errorMessage.value =
				err.response?.data.message ||
				'There was a problem saving your entry. Try again.'
			log.error(err.message, { err })
			saving.value = false
		})
}

function navigate(where: string) {
	$emit('close')
	router.push({ name: where })
}
</script>
