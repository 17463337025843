<template>
	<Mock>
		<div>
			<div
				class="flex space-x-2 sticky top-0 bg-white shadow overflow-x-auto z-10"
			>
				<div
					v-for="(wk, idx) in weeks"
					:key="`week-${idx}`"
					class="whitespace-nowrap"
					:class="[
						currentWeek == idx ? 'font-bold' : 'text-gray-400',
					]"
				>
					<template v-if="idx > 0">
						<router-link
							custom
							:to="{
								name: 'week-history',
								params: { week: idx },
							}"
							v-slot="{ navigate }"
						>
							<button
								@click="navigate"
								class="px-4 py-2"
								type="button"
							>
								Week {{ weeks - idx }}
							</button>
						</router-link>
					</template>
					<template v-else>
						<router-link
							custom
							:to="{ name: 'home' }"
							v-slot="{ navigate }"
						>
							<button
								@click="navigate"
								class="px-4 py-2"
								type="button"
							>
								Week {{ weeks - idx }}
							</button>
						</router-link>
					</template>
				</div>
			</div>

			<div class="grid grid-cols-2 text-center my-6">
				<div>
					<p class="text-2xl tracking-tighter font-semibold">7,500</p>
					<p class="text-sm tracking-tight text-gray-500">
						Miles Earned
					</p>
				</div>
				<div>
					<p class="text-2xl tracking-tighter font-semibold">
						14,000
					</p>
					<p class="text-sm tracking-tight text-gray-500">
						Miles Won
					</p>
				</div>
			</div>

			<div class="px-4 space-y-6">
				<ParlayResult
					v-for="x in 7"
					:key="`parlay-result-${x}`"
					entries="3"
					:firstPickBonus="x == 1 ? 1500 : 0"
					miles="2000"
					:picks="[
						{ name: 'Jambox', points: 4500, projection: 2500 },
						{
							name: 'Donny Buckets',
							points: 3750,
							projection: 2500,
						},
						{ name: 'Burner', points: 2000, projection: 1500 },
						{
							name: 'Floor General',
							points: 1500,
							projection: 500,
						},
						{
							name: 'Triple Threat',
							points: 3000,
							projection: 2750,
						},
						{ name: 'Victory', points: 1000, projection: 750 },
						{ name: 'Wild Beard', points: 4000, projection: 3675 },
					]"
				/>
			</div>
		</div>
	</Mock>
</template>
<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import Mock from '@/components/Mock.vue'
import ParlayResult from '@/components/Mockups/Partials/ParylayResult.vue'
import { useRoute } from 'vue-router'

const route = useRoute()

const currentWeek = ref(0)
const weeks = 6

onMounted(() => {
	currentWeek.value = route.params.week ?? 0
})
</script>
