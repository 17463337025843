import History from '@/components/Mockups/History.vue'
import HistoryIndex from '@/components/Mockups/HistoryIndex.vue'
import CreateEntry from '@/components/Pages/CreateEntry.vue'
import Leaderboard from '@/components/Pages/Leaderboard.vue'
import Login from '@/components/Pages/Login.vue'
import ViewEntries from '@/components/Pages/ViewEntries.vue'

const routes = [
	{
		path: '/',
		component: ViewEntries,
		name: 'home',
		meta: {
			requiresAuth: true,
		},
		children: [
			{
				path: 'week/:week_number',
				component: ViewEntries,
				name: 'week',
			},
		],
	},
	{
		path: '/leaderboard',
		component: Leaderboard,
		name: 'leaderboard',
		meta: {
			requiresAuth: false,
		},
	},
	{
		path: '/history/week/:week',
		component: History,
		name: 'week-history',
	},
	{
		path: '/history',
		component: HistoryIndex,
		name: 'history',
	},
	{
		path: '/create',
		component: CreateEntry,
		name: 'create',
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/entries',
		component: ViewEntries,
		name: 'entries',
	},
	{
		path: '/login',
		component: Login,
		name: 'login',
	},
]

export default routes
