<template>
	<div
		class="relative h-64 min-w-full rounded-xl overflow-x-auto pb-10 bg-transparent"
	>
		<div class="h-full relative min-w-full">
			<div
				class="absolute inset-0 text-sm text-gray-500 p-2 bg-gray-100 text-right rounded-b-xl"
			>
				Over
			</div>

			<div
				class="absolute inset-0 top-1/2 text-sm text-gray-500 p-2 bg-gray-300 text-right rounded-b-xl"
			>
				Under
			</div>

			<div class="w-5/6 flex gap-x-2 relative h-full items-end px-3">
				<div
					v-for="(week, index) in animatedWeeks"
					:key="index"
					class="flex h-full w-8 sm:w-12 items-center flex-col justify-end relative"
				>
					<div
						:class="
							getBarClass(
								week.total_points,
								week.projected_points,
							)
						"
						class="w-8 transition-all duration-500 ease-in-out relative flex items-end justify-center rounded-t-md"
						:style="{
							height: week.height,
						}"
					>
						<span
							class="absolute bottom-4 transform rotate-90 text-white hidden sm:block"
						>
							{{
								(
									(week.total_points /
										week.projected_points) *
									100
								).toFixed(0)
							}}%
						</span>
					</div>
					<div
						class="absolute top-full left-0 right-0 whitespace-nowrap text-center text-xs font-normal text-gray-400"
					>
						Wk. {{ week.week_number }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'

interface WeekData {
	[key: string]: {
		total_points: number
		projected_points: number
		week_number: number
	}
}

const props = defineProps<{
	data: string
}>()

const parsedData = JSON.parse(props.data) as { grouped_weeks: WeekData }

const weeks = Object.values(parsedData.grouped_weeks).map((week) => ({
	total_points: week.total_points,
	projected_points: week.projected_points,
	week_number: week.week_number,
	height: '0%', // Initial height for animation
}))

const animatedWeeks = ref(weeks)

const bestWeek = Math.max(
	...weeks.map((week) => week.total_points / week.projected_points),
)

function calculatePercentage(ratio) {
	return Math.round((ratio - 1) * 100) / 100
}

onMounted(() => {
	setTimeout(() => {
		animatedWeeks.value = weeks.map((week) => {
			let height = 0
			if (week.total_points <= week.projected_points) {
				height = 5 + (45 * week.total_points) / week.projected_points
			} else if (week.total_points / week.projected_points < 1.95) {
				const percentage = calculatePercentage(
					week.total_points / week.projected_points,
				)
				height = 50 + 50 * percentage
			} else {
				// set height to 50 (to clear the under) + 47 * overperformance against best week.
				// 47 gets us almost to the top of the chart container but doesn't touch it, for
				// style reasons

				height =
					50 +
					(47 * (week.total_points / week.projected_points)) /
						bestWeek
			}

			return {
				...week,
				height: `${height}%`,
			}
		})
	}, 0)
})

function getBarClass(points: number, projectedPoints: number) {
	if (points > projectedPoints) return 'bg-green-500'
	if (points < projectedPoints) return 'bg-red-500'
	return 'bg-gray-500'
}
</script>
