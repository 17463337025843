<template>
	<div class="h-full flex flex-col justify-start items-stretch">
		<slot name="header" v-if="showHeader">
			<header
				class="bg-gradient-to-br from-black to-teal-800 px-4 pt-4 pb-2 text-white flex justify-between items-end"
			>
				<HthHeader class="w-52" />
				<div>
					<p v-if="false">
						<span class="font-medium tracking-tight"> @jimsc </span>
						<fa icon="fa-solid fa-user" />
					</p>
					<p><button @click="login">Login</button></p>
				</div>
			</header>
		</slot>
		<section class="overflow-y-scroll pb-10 flex-grow">
			<!-- main content area -->
			<slot name="default" />
		</section>
		<!-- <section class="flex-grow"> -->
		<!-- spacer area -->
		<!-- </section> -->
		<footer>
			<!-- footer buttons area -->
			<div
				class="grid grid-cols-5 text-center text-gray-500 items-stretch"
			>
				<div class="p-4 bg-gray-100">
					<router-link
						custom
						:to="{ name: 'home' }"
						v-slot="{ navigate }"
					>
						<button @click="navigate">
							<fa icon="fa-solid fa-house" />
						</button>
					</router-link>
				</div>
				<div class="p-4 bg-gray-100">
					<router-link
						custom
						:to="{ name: 'history' }"
						v-slot="{ navigate }"
					>
						<button @click="navigate">
							<fa icon="fa-solid fa-calendar" />
						</button>
					</router-link>
				</div>
				<div class="bg-gray-100 flex justify-center items-center">
					<!-- <button class="absolute bg-teal-800 text-white w-full aspect-square rounded-full left-0 -top-7 text-3xl shadow-[3px_-3px_2px_1px_rgba(165,165,165,.8)]">
						<fa icon="fa-solid fa-plus" />
					</button> -->
					<div class="w-full px-4 flex-shrink-0">
						<slot name="center-button">
							<router-link
								custom
								:to="{ name: 'create' }"
								v-slot="{ navigate }"
							>
								<button
									@click="navigate"
									class="bg-teal-800 text-white w-full aspect-square rounded-full"
								>
									<fa icon="fa-solid fa-plus" />
								</button>
							</router-link>
						</slot>
					</div>
				</div>
				<div class="p-4 bg-gray-100">
					<button><fa icon="fa-solid fa-user-group-simple" /></button>
				</div>
				<div class="p-4 bg-gray-100">
					<button><fa icon="fa-solid fa-user-large" /></button>
				</div>
			</div>
		</footer>
	</div>
</template>
<script lang="ts" setup>
import auth from '@/modules/auth'
import HthHeader from './HthHeader.vue'

withDefaults(
	defineProps<{
		showHeader: boolean
	}>(),
	{
		showHeader: true,
	},
)

function login() {
	auth.redirectToLogin()
}
</script>
