import { defineStore } from 'pinia'
import { ref } from 'vue'

const versionString =
	import.meta.env.MODE === 'development'
		? import.meta.env.VITE_APP_VERSION + '-dev'
		: import.meta.env.VITE_APP_VERSION

export const useAppStore = defineStore('app', () => {
	let initialized = false
	// const overlay = ref<boolean>(false)
	// const mobileOnlyOverlay = ref<boolean>(false)
	const modalStackCount = ref<number>(0)

	function init() {
		initialized = true

		return {
			initialized,
			version: versionString,
			// overlay,
			// mobileOnlyOverlay,
			modalStackCount,
		}
	}

	return init()
})
