import axios, { AxiosResponse } from 'axios'

// const log = useLogger()
const log = {
	error: function (message: string, data: any = {}) {
		console.log(message, data)
	},
}

export function apiUrl(path: string): string {
	return (
		String(import.meta.env.VITE_API_ROOT).rtrim('/') + '/' + path.ltrim('/')
	)
}

export function rainbowsApiUrl(path: string): string {
	return (
		String(import.meta.env.VITE_RAINBOWS_API_BASE_URL).rtrim('/') +
		'/' +
		path.ltrim('/')
	)
}

export const UNKNOWN_ERROR = -1
export interface ValidationErrors {
	[field: string]: string[]
}

export const isObject = (thing: any) =>
	thing !== null && typeof thing === 'object'

export function isSuccessful(response: AxiosResponse): boolean {
	return response.status >= 200 && response.status < 300
}

export const isValidationError = (payload: any): payload is ValidationError =>
	isObject(payload) && !!payload.message && !!payload.errors

export const isApiError = (payload: any): payload is ApiError =>
	isObject(payload) && !(undefined == payload.success) && !!payload.message

export class ApiError extends Error {
	constructor(message: string) {
		super(message)
	}
}

export class ValidationError extends ApiError {
	errors: ValidationErrors
	constructor(message: string, errors: ValidationErrors) {
		super(message)
		this.errors = errors
	}
}

export interface ApiResponse<T = ApiError> {
	success: boolean
	response: AxiosResponse
	error?: T | ApiError
}

export async function handleApiResponse(
	responsePromise: Promise<AxiosResponse>,
): Promise<ApiResponse> {
	return normalizeApiError(
		responsePromise
			.then((response) => {
				if (isSuccessful(response)) {
					return {
						success: true,
						response,
					} as ApiResponse
				}

				throw new Error('received non-success response')
			})
			.catch((err) => {
				if (axios.isAxiosError(err)) {
					if (isValidationError(err.response!.data)) {
						return Promise.reject({
							success: false,
							response: err.response,
							error: new ValidationError(
								err.response!.data.message,
								err.response!.data.errors,
							),
						} as ApiResponse<ValidationError>)
					} else if (isApiError(err.response!.data)) {
						return Promise.reject({
							success: false,
							response: err.response,
							error: new ApiError(err.response!.data.message),
						} as ApiResponse<ApiError>)
					}

					return Promise.reject({
						success: false,
						response: err.response,
						error: new ApiError(err.message),
					})
				}

				log.error('Failed to create entry: ' + err.message, {
					request: {
						method: err.config.method,
						uri: err.config.url,
						data: err.config.data,
					},
					response: {
						status: err.response.status,
						data: err.response.data,
					},
				})

				throw err
			}),
	)
}

export async function normalizeApiError(
	apiResponsePromise: Promise<ApiResponse>,
): Promise<ApiResponse> {
	return apiResponsePromise
		.then(
			(r) => r,
			(e) => e,
		)
		.catch((e) => {
			return {
				success: false,
				response: {},
				error: new ApiError(
					`An unexpected error occurred: ${e.message}`,
				),
			}
		})
}
