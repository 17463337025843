<template>
	<layout-main>
		<div
			class="max-w-screen-md mx-auto text-center h-full flex flex-col justify-center my-12"
		>
			<h3 class="font-medium text-2xl tracking-tight">Pick Six</h3>
			<h2 class="text-xl tracking-tight text-gray-500">
				By Home Team Heroes
			</h2>

			<p class="my-6">
				<a
					href="/auth/login"
					class="bg-teal-800 text-white rounded-xl px-8 py-2 text-xl"
				>
					Log in with your Blokpax Account
				</a>
			</p>
		</div>
	</layout-main>
</template>
<script lang="ts" setup>
import { useRoute, useRouter } from 'vue-router'
import { useAuthStore } from '../../stores/AuthStore'
import { onUnmounted, watch } from 'vue'

const router = useRouter()
const authStore = useAuthStore()
const route = useRoute()

const stopHandle = watch(
	() => authStore.authenticated,
	(authenticated) => {
		if (authenticated && route.query.redirect_to) {
			router.push({ path: route.query.redirect_to as string })
		} else {
			router.push({ name: 'home' })
		}
	},
)

onUnmounted(() => {
	stopHandle()
})
</script>
