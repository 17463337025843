declare interface String {
	rtrim(rm: string): string
	ltrim(rm: string): string
}

String.prototype.rtrim = function (this: string, rm: string) {
	return this.valueOf().replace(new RegExp(rm + '+$'), '')
}

String.prototype.ltrim = function (this: string, rm: string) {
	return this.valueOf().replace(new RegExp('^' + rm + '+'), '')
}
