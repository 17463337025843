import { library } from '@fortawesome/fontawesome-svg-core'

import {
	faCheckCircle,
	faCircleQuestion,
	faEye,
	faRightFromBracket,
	faSpinner,
} from '@awesome.me/kit-58ff991e8b/icons/classic/regular'

library.add(
	faCheckCircle,
	faCircleQuestion,
	faSpinner,
	faEye,
	faRightFromBracket,
)
