<template>
	<div
		class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75 overflow-hidden"
		@click.self="closeModal"
	>
		<div
			class="bg-white rounded-lg shadow-lg p-4 md:max-w-lg w-12/12 md:w-3/4 h-5/6 flex flex-col justify-between relative"
		>
			<div class="flex justify-end z-50 relative">
				<button @click="closeModal" class="z-50">
					<fa icon="fa-solid fa-times" />
				</button>
			</div>

			<div class="flex-grow overflow-auto px-4">
				<div class="grid grid-cols-2 justify-between">
					<div>
						<img
							:src="hero.image_url"
							alt="Hero Image"
							class="w-full rounded-md"
						/>
					</div>

					<div class="px-2 flex flex-col justify-between">
						<div>
							<h2 class="text-xl text-gray-700 font-semibold">
								{{ hero.hero_name }}
							</h2>
							<p class="text-gray-500 text-sm">
								Favorite Player: {{ hero.player_name }}
							</p>

							<p class="text-gray-500 text-sm">
								Sport: {{ hero.sport.toUpperCase() }}
							</p>

							<div class="text-gray-500 text-sm md:hidden mt-4">
								<div>
									Projected:
									{{ $format(hero.projected_points) }}
								</div>
							</div>
						</div>

						<div
							class="rounded-md hidden md:block text-center mb-8"
						>
							<div class="text-4xl text-gray-700 font-bold mb-2">
								{{ $format(hero.projected_points) }}
							</div>
							<div class="text-gray-700 font-bold">
								Projected HTH Score
							</div>
						</div>
					</div>
				</div>

				<div
					v-if="disabled && props.createModal"
					class="bg-red-500 p-2 text-white font-normal w-1/4 text-center rounded-md mt-4"
				>
					Disabled
				</div>

				<p class="mt-6 font-medium text-gray-700">
					Performance against projections
				</p>

				<SparkGraph
					:data="hero.data"
					:key="animationKey"
					class="mt-0"
				/>

				<div>
					<div class="bg-gray-100 border-b border-gray-300 z-20">
						<div
							class="grid grid-cols-1 md:grid-cols-[1fr,2fr,2fr,2fr]"
						>
							<div
								class="bg-gray-200 py-3 px-6 md:text-left text-center w-full text-gray-600 uppercase text-sm leading-normal"
							>
								Week
							</div>
							<div
								class="bg-gray-200 py-3 px-6 text-left w-full text-gray-600 uppercase text-sm leading-normal hidden md:block"
							>
								Actual
							</div>
							<div
								class="bg-gray-200 py-3 px-6 text-left w-full text-gray-600 uppercase text-sm leading-normal hidden md:block"
							>
								Projected
							</div>
							<div
								class="bg-gray-200 py-3 px-6 text-left w-full text-gray-600 uppercase text-sm leading-normal hidden md:block"
							>
								Games
							</div>
						</div>
					</div>

					<div class="text-gray-600 text-sm font-light">
						<div
							v-for="(points, week) in parsedWeeks"
							:key="week"
							class="border-b border-gray-200 hover:bg-gray-100 cursor-pointer"
							@click="expandTableRow(week)"
						>
							<div
								class="grid grid-cols-1 md:grid-cols-[1fr,2fr,2fr,2fr]"
							>
								<div class="flex flex-row justify-between">
									<div
										class="md:hidden font-medium py-3 ml-2 text-left"
									>
										Week:
									</div>
									<div
										class="py-3 px-6 -ml-4 md:ml-2 text-left md:text-center whitespace-nowrap"
									>
										{{ points.week_number }}
									</div>
								</div>
								<div
									class="flex flex-row -mt-4 md:mt-0 justify-between"
								>
									<div
										class="md:hidden font-medium py-3 ml-2 text-left"
									>
										Actual Points:
									</div>
									<div class="py-3 px-6 text-center md:ml-6">
										{{ formatNumber(points.total_points) }}
									</div>
								</div>
								<div
									class="flex flex-row -mt-4 md:mt-0 justify-between"
								>
									<div
										class="md:hidden font-medium py-3 ml-2 text-left"
									>
										Projected Points:
									</div>
									<div class="py-3 px-6 text-center md:ml-6">
										{{
											formatNumber(
												points.projected_points,
											)
										}}
									</div>
								</div>
								<div
									class="flex flex-row text-center -mt-4 md:mt-0 justify-between"
								>
									<div
										class="md:hidden font-medium py-3 ml-2 text-left"
									>
										Number of Games:
									</div>
									<div class="py-3 px-6 text-center md:ml-8">
										{{
											formatNumber(points.number_of_games)
										}}
									</div>
								</div>
							</div>

							<!-- Expanded table row -->
							<div
								v-if="expandedRows.includes(week)"
								class="bg-gray-100"
							>
								<div
									class="grid grid-cols-1 md:grid-cols-3 border-t border-gray-300"
								>
									<div
										class="bg-gray-200 py-2 px-4 text-left text-gray-600 hidden md:block uppercase text-sm leading-normal"
									>
										Game Date
									</div>
									<div
										class="bg-gray-200 py-2 px-4 text-left text-gray-600 hidden md:block uppercase text-sm leading-normal"
									>
										Earned Points
									</div>
									<div
										class="bg-gray-200 py-2 px-4 text-left text-gray-600 hidden md:block uppercase text-sm leading-normal"
									>
										Opponent
									</div>
								</div>
								<div
									class="font-bold md:hidden mt-6 text-gray-700"
								>
									<div class="ml-2">Games</div>
								</div>

								<div
									v-for="game in points.games"
									:key="game.date"
									class="grid grid-cols-1 md:grid-cols-3 border-b border-gray-300"
								>
									<div class="flex flex-row justify-between">
										<div
											class="md:hidden font-medium py-2 ml-2 text-left"
										>
											Game Date:
										</div>
										<div class="py-2 px-4 text-center">
											{{
												DateTime.fromISO(
													game.game_date,
												).toFormat('M/dd/yyyy')
											}}
										</div>
									</div>

									<div
										class="flex flex-row -mt-4 md:mt-0 md:ml-6 justify-between"
									>
										<div
											class="md:hidden font-medium py-2 ml-2 text-left"
										>
											Points Earned:
										</div>
										<div class="py-2 px-4 text-center">
											{{
												formatNumber(game.earned_points)
											}}
										</div>
									</div>

									<div
										class="flex flex-row -mt-4 md:mt-0 md:ml-6 justify-between"
									>
										<div
											class="md:hidden font-medium py-2 ml-2 text-left"
										>
											Opponent:
										</div>
										<div class="py-2 px-4">
											{{ game.opponent }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="flex justify-between mt-4 z-50 relative">
				<button
					@click="prevPlayer"
					:disabled="isFirstPlayer"
					class="bg-gray-200 px-4 py-2 rounded"
				>
					Previous
				</button>

				<div v-if="props.createModal">
					<button
						v-if="!selected"
						@click="handlePick"
						class="inline-block px-4 py-2 bg-teal-800 rounded-full text-white aspect-square shadow-md disabled:bg-gray-100 disabled:text-gray-200"
						:disabled="disabled"
					>
						<fa icon="fa-solid fa-plus" />
					</button>
					<button
						v-else
						@click="handlePick"
						class="inline-block px-4 py-2 bg-red-300 text-white rounded-full aspect-square shadow-md"
					>
						<fa icon="fa-solid fa-minus" />
					</button>
				</div>

				<button
					@click="nextPlayer"
					:disabled="isLastPlayer"
					class="bg-gray-200 px-4 py-2 rounded"
				>
					Next
				</button>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, computed, ref } from 'vue'
import { HeroPick, Hero } from '@/types/Hero'
import SparkGraph from '@/components/Heroes/ProjectionSparkGraph.vue'
import { DateTime } from 'ts-luxon'

const props = defineProps<{
	hero: HeroPick
	isDisabled?: boolean
	selected?: boolean
	heroList: Hero[]
	createModal: boolean
	currentIndex: number
}>()

const emit = defineEmits<{
	close: []
	change: [number]
	add: [hero: Hero]
	remove: [hero: Hero]
}>()

function addHero() {
	emit('add', props.hero)
}

function removeHero() {
	emit('remove', props.hero)
}

const expandedRows = ref([])

function expandTableRow(week: string) {
	if (expandedRows.value.includes(week)) {
		expandedRows.value = expandedRows.value.filter((w) => w !== week)
	} else {
		expandedRows.value.push(week)
	}
}
function handlePick() {
	if (props.isDisabled) return
	if (props.selected) {
		removeHero()
	} else {
		addHero()
	}
}

function closeModal() {
	emit('close')
}

function prevPlayer() {
	if (props.currentIndex > 0) {
		changeKey()
		emit('change', props.currentIndex - 1)
	}
}

function nextPlayer() {
	if (props.currentIndex < props.heroList.length - 1) {
		changeKey()
		emit('change', props.currentIndex + 1)
	}
}

const isFirstPlayer = computed(() => {
	return props.currentIndex === 0
})
const isLastPlayer = computed(() => {
	return props.currentIndex === props.heroList.length - 1
})

const disabled = computed(() => {
	return (
		!(props.hero.projected_points > 0) || props.hero.actual_points != null
	)
})

var animationKey = 0

function changeKey() {
	animationKey++
}

function formatNumber(value: number) {
	if (typeof value !== 'number') {
		return value
	}
	return value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const parsedData = computed(() => {
	return JSON.parse(props.hero.data) as {
		grouped_weeks: {
			[key: string]: {
				projected_points: number
				total_points: number
				number_of_games: number
				data: string
			}
		}
	}
})
const parsedWeeks = computed(() => parsedData.value.grouped_weeks)
</script>
