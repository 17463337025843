import { DeleteEntryResponse } from '@/types/Entry'
import axios from 'axios'
import { ApiResponse, apiUrl, handleApiResponse } from '../util'

export async function deleteEntry(
	entry_id: string,
): Promise<DeleteEntryResponse> {
	return handleApiResponse(
		axios({
			method: 'DELETE',
			url: apiUrl(`/api/account/entry/${entry_id}`),
		}),
	).then((apiResponse: ApiResponse): DeleteEntryResponse => {
		if (apiResponse.success) {
			return {
				success: true,
			}
		}

		return {
			success: false,
			message: apiResponse.error!.message,
		}
	})
}
