import { User } from '@/types/User'
import axios from 'axios'

function apiLoginUri(): string {
	return import.meta.env.VITE_API_ROOT + '/api/auth/redirect'
}

function apiAuthUri(): string {
	return import.meta.env.VITE_API_ROOT + '/api/auth/login'
}

function apiAccountUri(): string {
	return import.meta.env.VITE_API_ROOT + '/api/account'
}

export default {
	redirectToLogin,
	getLogoutUrl,
	authenticate,
	account,
}

async function redirectToLogin(returnPath: string = '/'): Promise<void> {
	let rdr = await getLoginUrl(returnPath)
	if (rdr) {
		window.top.location = String(rdr)
	}
}

async function authenticate(code: string, state: string): Promise<any> {
	try {
		const response = await axios({
			url: apiAuthUri(),
			method: 'POST',
			data: {
				code,
				state,
			},
			headers: {
				Accept: 'application/json',
			},
		})

		if (response.status === 200) {
			return response.data
		}
	} catch (err) {
		//
	}

	return null
}

async function account(accessToken: string): Promise<User | null> {
	const response = await axios(apiAccountUri(), {
		headers: {
			Authorization: 'Bearer ' + accessToken,
		},
	})

	if (response.status === 200) {
		return response.data.data
	}

	return null
}

async function getLoginUrl(
	returnPath: string = '/',
): Promise<string | boolean> {
	try {
		const response = await axios({
			url: apiLoginUri(),
			method: 'POST',
			data: {
				redirect_to: returnPath,
			},
			headers: {
				Accept: 'application/json',
			},
		})

		if (response.status === 200) {
			return response.data.uri
		}
	} catch (err) {
		// errors.log(err)
	}

	return false
}

async function getLogoutUrl(
	returnPath: string = '/',
): Promise<string | boolean> {
	return '/'
}
