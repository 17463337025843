import axios from 'axios'
import { ApiResponse, apiUrl, handleApiResponse } from '../util'

export async function getLoginUrl(redirectTo: string): Promise<string | null> {
	return handleApiResponse(
		axios({
			url: apiUrl('/api/auth/login-uri'),
			method: 'POST',
			data: {
				redirect_to: redirectTo,
			},
		}),
	)
		.then((response: ApiResponse) => {
			if (response.success) {
				return response.response.data.uri
			}

			return null
		})
		.catch(() => null)
}
