import { LeaderboardResponse } from '@/types/Leaderboard'
import axios from 'axios'
import { ApiResponse, handleApiResponse, rainbowsApiUrl } from '../util'

export async function seasonLeaderboard(
	seasonSlug: string,
): Promise<LeaderboardResponse> {
	return handleApiResponse(
		axios({
			method: 'GET',
			url: rainbowsApiUrl(`/api/season-leaderboard/${seasonSlug}`),
		}),
	).then((apiResponse: ApiResponse): LeaderboardResponse => {
		if (apiResponse.success) {
			return {
				success: true,
				leaderboard: apiResponse.response.data.leaderboard,
			}
		}

		return {
			success: false,
			message: apiResponse.error!.message,
		}
	})
}
