import { SaveEntryResponse } from '@/types/Entry'
import axios from 'axios'
import { ApiResponse, apiUrl, handleApiResponse } from '../util'

export async function createEntry(
	season: string,
	week: string,
	count: Number,
	heroes: string[],
): Promise<SaveEntryResponse> {
	return handleApiResponse(
		axios({
			method: 'POST',
			url: apiUrl(`/api/account/season/${season}/weeks/${week}/entry`),
			data: {
				quantity: count,
				heroes: heroes,
			},
		}),
	).then((apiResponse: ApiResponse): SaveEntryResponse => {
		if (apiResponse.success) {
			return {
				success: true,
				entry: apiResponse.response.data,
			}
		}

		return {
			success: false,
			message: apiResponse.error!.message,
		}
	})
}
