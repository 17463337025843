import * as api from '@/api'
import { AccountResponse, User } from '@/types/User'
// import EventBus from '@/util/eventBus'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useAuthStore = defineStore('auth', () => {
	// const eventEmitter = new EventBus()
	const user = ref<User | null>(null)

	function init() {
		return {
			user,
			authenticated,

			getLoginUrl,
			getAccount,
			logout,
		}
	}

	async function getLoginUrl(redirectTo: string): Promise<string | null> {
		return await api.auth.getLoginUrl(redirectTo)
	}

	async function getAccount(): Promise<User | null> {
		try {
			return await api.auth
				.account()
				.then((response: AccountResponse) => {
					if (response.success) {
						user.value = response.account!
						return response.account!
					}

					return null
				})
		} catch (e) {
			clearAuthState()
		}

		return null
	}

	function clearAuthState() {
		user.value = null
	}

	const authenticated = computed(() => {
		return !!user.value
	})

	function logout() {
		api.auth.logout()
	}

	return init()
})
