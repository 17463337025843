import axios from 'axios'
import { ApiResponse, handleApiResponse } from '../util'

export async function logout(): Promise<void> {
	return handleApiResponse(
		axios({
			url: '/logout',
			method: 'POST',
			headers: {
				Accept: 'application/json',
			},
		}),
	)
		.then((response: ApiResponse) => {
			if (response.success) {
				return response.response.data
			}
			throw new Error('failed to log out')
		})
		.catch(() => null)
}
