import {
	LogEvent,
	LoggerHook,
	useLogger as getLogger,
	createLogger as makeLogger,
} from 'vue-logger-plugin'

const beforeHooks: LoggerHook[] = []
const afterHooks: LoggerHook[] = []

export function withBeforeHook(callback: LoggerHook) {
	beforeHooks.push(callback)
}

export function withAfterHook(callback: LoggerHook) {
	afterHooks.push(callback)
}

export function withBugsnag(bugSnag) {
	withBeforeHook({
		run: (log: LogEvent) => {
			let [message, metadata] = log.argumentArray
			let [level] = log.level
			bugSnag.notify(new Error(`${level}: ${message}`), (evt) => {
				evt.severity = level
				return metadata
			})
		},
	})
}

export function useLogger() {
	return getLogger()
}

export function createLogger(options: any = {}) {
	const env = options.env || 'dev'
	const level = options.logLevel || (env === 'dev' ? 'debug' : 'info')

	return makeLogger({
		enabled: true,
		consoleEnabled: env !== 'production',
		level,
		beforeHooks: beforeHooks,
		afterHooks: afterHooks,
	})
}
